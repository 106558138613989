html {
    height: 100%;  
}

body {
    height: 100%;
    background-color: #e6eef2;
    color: #333;
    font-family: 'Open Sans', sans-serif;
}

.header {
    text-align: center;
    padding: 70px 15px 90px;

    @media (min-width: 992px) and (max-width: 1199px) {
        padding: 60px 15px 80px;
    }
    @media (min-width: 768px) and (max-width: 991px) {
        padding: 40px 15px 60px;
    }
    @media (min-width: 576px) and (max-width: 767px) {
        padding: 40px 15px 60px;
    }
    @media (min-width: 460px) and (max-width: 575px) {
        padding: 40px 15px 60px;
    }
    @media (max-width: 459px) {
        padding: 30px 15px 50px;
    }
}
.header__title {
    font-size: 42px;
    font-weight: 700;
    letter-spacing: -.04em;
    position: relative;
    padding-bottom: 16px;
    margin-bottom: 16px;
    
    &::before {
        display: block;
        content: '';
        position: absolute;
        width: 60px;
        height: 3px;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        background-color: #ffd599;
    }
}
.header__subtitle {
    font-size: 18px;
    font-weight: 600;
    letter-spacing: -.02em;
    
    color: #676f73;
}



.card {
    border: none;
    border-radius: 0;
    box-shadow: 0 0 0 1px rgba(#000, .02), 0 1px 4px rgba(#cfdee5, 1);
}


.page {
    color: inherit;
    
    &:hover {
        color: inherit;
        text-decoration: none;
    }
}
.page__title {
    font-size: 20px;
    font-weight: 600;
    letter-spacing: -.04em;
    text-align: center;
    margin-bottom: 26px;

    color: #676f73;

    @media (max-width: 499px) {
        font-size: 18px;
    }
    @media (max-width: 359px) {
        font-size: 16px;
    }
}
.page__card-body {
    padding: 8px;
    box-shadow: inset 0 0 0 0 #ffd599, 0 0 0 0 #ffd599;
    transition: box-shadow .2s;

    &:hover {
        box-shadow: inset 0 0 0 2px #ffd599, 0 0 0 1px #ffd599;
    }
    
    img {
        width: 100%;
    }
    
    @media (min-width: 960px) and (max-width: 1199px) {
        padding: 10px;
    }
}
.page__versions {
    display: flex;
    margin-bottom: 24px;
    margin-top: -10px;
    justify-content: center;
    font-weight: 600;
    font-size: 15px;

    a {
        display: block;
        color: inherit;
        border-radius: 2px;
        padding: 4px 36px 5px;
        border: 1px solid rgba(#000, .1);
        background-clip: padding-box;
        transition: background-color .12s;
    }
    a + a {
        margin-left: 12px;
    }
    a:hover {
        background-color: rgba(#000, .06);
        text-decoration: none;
    }
}


.copyright {
    margin-top: 30px;
    text-align: center;
    height: 100px;
    font-size: 15px;
    line-height: 100px;

    a {
        color: #676f73;
    }
    a:hover {
        color: #333;
    }
    a, a:hover {
        text-decoration: none;
    }
}


@media (max-width: 767px) {
    .page__versions {
        margin-bottom: 16px;
        margin-top: -16px;
        font-size: 14px;

        a {
            padding: 3px 28px 4px;
        }
        a + a {
            margin-left: 8px;
        }
    }
}
@media (max-width: 499px) {
    .page__versions {
        display: block;
        text-align: center;

        a + a {
            margin-top: 8px;
            margin-left: 0;
        }
    }
}
@media (max-width: 359px) {

}